@import '../../../../assets/styles/medias';
@import '../../../../assets/styles/placeholders';

.container {
  @extend %flex-column;
  padding: 25px 40px 20px 40px;
  max-width: 630px;
  max-height: 760px;
  width: 100%;
  background-color: white;
  position: relative;

  @include media_lg {
    padding: 25px 70px 25px 70px;
  }
}

.createAccount {
  @extend %flex-column;
  color: black;
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  text-transform: uppercase;
  padding: 40px 30px;
  background: var(--gradient-1);
  color: var(--white);
  text-align: center;

  @include media_xl {
    font-size: 36px;
    line-height: 54px;
  }
}

.subtitle {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: var(--color-2);
  margin-bottom: 31px;

  @include media_md {
    font-size: 24px;
    line-height: 28px;
  }
}

.modalBtn {
  @extend %flex-column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid var(--color-border);
  margin-bottom: 20px;
  height: 110px;
  cursor: pointer;
  &:hover {
    background: linear-gradient(95.63deg, #79ce45 0%, #2bcc82 100%);
    [class*='modalBtnBigText'] {
      color: #ffffff !important;
    }
    [class*='modalBtnText'] {
      color: #ffffff !important;
    }
  }

  @include media_md {
    height: 130px;
  }
}

.modalBtnBigText {
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  color: #00394f;
  text-align: center;

  @media (max-width: 900px) {
    font-size: 28px;
    line-height: 34px;
  }
}

.modalBtnText {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #717171;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 20px;
  }
}

.wrapperCloseIcon {
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  i {
    font-size: 30px;
  }
}

.createAccountModalContent button {
  svg path {
    fill: var(--white);
  }
}

.bookImage {
  margin: 0 auto;
  width: 200px;
  height: 150px;
  object-fit: contain;
  margin-bottom: 25px;
}
