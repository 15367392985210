@import '../../../../assets/styles/medias';

.amazonBookCard {
  margin: 0 10px;
}

.amazonBooksDesktopWrapper {
  display: none;

  @include media_xl {
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
    gap: 20px;
  }
}
