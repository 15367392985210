@import '../../../../../assets/styles/medias';
@import '../../../../../assets/styles/placeholders';

.editCollectionIcon {
  width: 28px;
  height: 28px;
  flex-shrink: 0;

  path {
    fill: var(--color-2);
  }
}

.editCollectionForm {
  @extend %flex-column;
  justify-content: center;
  gap: 50px;
}

.editCollectionFormInputs {
  @extend %flex-column;
  justify-content: center;
  gap: 30px;
}

.editCollectionFormInput {
  font-size: 18px;
  line-height: 21px;
  padding: 12px 20px;
  border: 1px solid var(--color-border);

  &:focus {
    outline: none;
    border-color: var(--color-1);
    color: var(--color-1);
  }
}

.editCollectionFormInput::placeholder {
  color: var(--black);
}

.editCollectionFormButtons {
  @extend %flex-column;
  align-items: center;
  gap: 15px;

  @include media_sm {
    flex-direction: row;
    justify-content: center;
    gap: 65px;
  }
}

.editCollectionFormButton {
  padding: 10px;
  min-width: 216px;

  @include media_sm {
    padding: 20px;
  }
}
