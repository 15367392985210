@import '../../../../assets/styles/medias';

.facility {
  background: url('../../../../assets/img/facility-bg.webp') center center no-repeat;
  background-size: cover;
  padding-bottom: 120px;
  padding-top: 120px;
}

.facilityContainer {
  @include media_custom(768px, 1199px) {
    max-width: 765px;
  }
}

.facilityHeading {
  margin-bottom: 60px;
  text-align: center;
}

.facilityTitle {
  font-size: 29px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  color: var(--white);
  margin-bottom: 25px;

  @include media_xl {
    font-size: 52px;
    line-height: 61px;
  }
}

.facilityDescription {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  text-align: center;
}

.facilityContentDesktop {
  display: none;

  @include media_xl {
    display: grid;
    grid-template-columns: repeat(auto-fit, 355px);
    justify-content: center;
    justify-items: center;
    gap: 25px;
  }

  @include media_custom_min_width(1400px) {
    grid-template-columns: repeat(auto-fit, 400px);
    gap: 30px;
  }
}
