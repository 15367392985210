@import '../../../assets/styles/medias';

.recommendedMaterialsCarousel {
  width: 300px;

  @include media_custom(768px, 1249px) {
    width: 632px;
  }

  @include media_custom(1250px, 1399px) {
    width: 964px;
  }

  @include media_custom_min_width(1400px) {
    width: 1296px;
  }
}

.recommendedMaterialsCarouselWithTwoItems {
  .recommendedMaterialsCarouselContainer {
    @include media_custom(1250px, 1399px) {
      width: 632px;
    }

    @include media_custom_min_width(1400px) {
      width: 632px;
    }
  }
}

.recommendedMaterialsCarouselWithThreeItems {
  @include media_custom_min_width(1400px) {
    width: 964px;
  }
}

.recommendedMaterialsCarouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  :global(.slick-slide > div) {
    height: 100%;
  }

  :global(.slick-list) {
    display: flex;
    justify-content: flex-start;
  }

  :global(.slick-slider) {
    width: 100%;
  }

  :global(.slick-track) {
    display: flex !important;
    justify-content: center;
    gap: 32px;
  }
}

.recommendedMaterialsCarouselWithOneItem {
  .recommendedMaterialsCarouselContainer {
    width: 300px;
  }
}

.recommendedMaterialsCarouselTitleContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  color: var(--color-2);
  text-align: left;

  @include media_md {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  @include media_md {
    margin-bottom: 47px;
  }
}

.centered {
  @include media_md {
    justify-content: center;
  }
}

.recommendedMaterialsCarouselCommonMobileTitle,
.recommendedMaterialsCarouselMobileTitle {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-2);
}

.recommendedMaterialsCarouselCommonMobileTitle {
  margin-bottom: 12px;

  @include media_md {
    display: none;
  }
}

.recommendedMaterialsCarouselMobileTitle {
  @include media_md {
    display: none;
  }
}

.recommendedMaterialsCarouselDesktopTitle {
  display: none;

  @include media_md {
    display: block;
    font-size: 26px;
    font-weight: 600;
    line-height: 30px;
    font-weight: 600;
    color: var(--color-2);
  }

  @include media_custom_min_width(1300px) {
    font-size: 36px;
    line-height: 42px;
  }
}

.recommendedMaterialsCarouselCustomTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-2);
}
