@import '../../../../assets/styles/medias';

.bookContentDesktop {
  display: none;

  @include media_xl {
    display: grid;
    grid-template-columns: repeat(auto-fit, 255px);
    justify-content: center;
    justify-items: center;
    gap: 30px;

    @include media_custom_min_width(1350px) {
      gap: 90px;
    }
  }
}
