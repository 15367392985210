.video-player-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
}

.video-player {
  position: absolute;
  inset: 0;
}

.video-player__icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 80px;
  height: 80px;
  text-align: center;
  line-height: 80px;
  background: var(--white);
  color: red;
  font-size: 26px;
  z-index: 2;
  display: inline-block;
  border-radius: 50%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    border-radius: 50%;
    animation: 1s video 1.5s linear infinite;
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--white);
    border-radius: 50%;
    animation: 1s video linear infinite;
    z-index: -1;
  }

  i {
    transition: 0.3s;
  }

  &:hover {
    i {
      color: #23cc88;
    }
  }
}

@keyframes video {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.react-player__preview {
  background-repeat: no-repeat;
  background-position: top center !important;
}
