@import '../../../../assets/styles/medias';

.footerCard {
  margin-bottom: 50px;
}

.footerCardTitle {
  font-size: 22px;
  line-height: 100%;
  font-weight: 700;
  color: var(--white);
  position: relative;
  margin: -2px 0 30px;
  padding-bottom: 20px;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 1px;
    background: linear-gradient(to left, transparent, #804bff);
  }

  @include media_md {
    font-size: 24px;
  }
}
