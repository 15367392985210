@import '../../../../../assets/styles/medias';
@import '../../../../../assets/styles/placeholders';

.createCollectionModal {
  max-width: 640px;
  width: 100%;
}

.createCollectionIcon {
  flex-shrink: 0;
}

.createCollectionForm {
  @extend %flex-column;
  justify-content: center;
  gap: 50px;
}

.createCollectionFormInputs {
  @extend %flex-column;
  justify-content: center;
  gap: 30px;
}

.createCollectionFormButtons {
  @extend %flex-column;
  align-items: center;
  gap: 15px;

  @include media_sm {
    flex-direction: row;
    justify-content: center;
    gap: 65px;
  }
}

.createCollectionFormButton {
  padding: 10px;
  min-width: 216px;
  width: fit-content;
  align-self: flex-end;

  @include media_sm {
    padding: 20px;
  }
}
