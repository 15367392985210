@import '../../../assets/styles/medias';
@import '../../../assets/styles/placeholders';
$custom-width: 1024px;

.headerLogo {
  width: 120px;

  @include media_xl {
    width: 160px;
  }

  @include media_custom_min_width(1400px) {
    width: 180px;
  }

  @include media_custom_min_width(1500px) {
    width: 210px;
  }
}

.headerBottom {
  @extend %flex-align-center;
  position: relative;
  justify-content: space-between;
  padding: 30px 0;
  gap: 3px;
}

.headerBottomSearchContainer {
  @extend %flex-align-center;
  justify-content: center;
  gap: 16px;

  @include media_md {
    gap: 25px;
  }

  @include media_lg {
    position: relative;
  }
}

.headerBottomMenuBtn {
  @extend %flex-center;
  width: 38px;
  height: 38px;
  background: var(--gradient-1);
  color: var(--white);
  padding: 0;

  i {
    font-size: 20px;
  }
}

.headerBottomEditorButton {
  display: none;

  @include media_custom_min_width($custom-width) {
    display: inline-block;
  }

  button {
    @include media_lg {
      font-size: 15px;
      display: flex;
      gap: 6px;
      box-sizing: border-box;
      padding: 0;
      padding: 10px;
      align-items: center;
      max-width: 205px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @include media_xl {
      font-size: 16px;
      padding: 21px 20px;
      gap: 8px;
      max-width: 240px;
    }

    svg {
      flex-shrink: 0;
    }
  }
}

.headerBottomMobileNavigation {
  display: none;
  overflow: hidden;
  background: linear-gradient(11deg, #8ecf35, #23cc88);
  padding: 20px 25px;
}

.navigationVisible {
  display: block;
}

.headerBottomMobileNavigation {
  @include media_custom_min_width($custom-width) {
    display: none;
  }
}

.headerBottomDesktopNavigation {
  position: relative;
  @extend %flex-align-center;
  justify-content: flex-start;
}

.headerBottomDesktopNavigation {
  display: none;

  @include media_custom_min_width($custom-width) {
    display: block;
  }
}

.headerBottomMobileMenuAndEditorLink {
  @extend %flex-align-center;
  gap: 8px;

  @include media_custom_min_width($custom-width) {
    display: none;
  }
}

.headerBottomMobileEditorBtn {
  @extend %flex-center;
  width: 38px;
  height: 38px;
  background: var(--gradient-1);
  color: var(--white);
  padding: 0;
}
