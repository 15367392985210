.childFormTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: var(--black);
  margin-top: 35px;
}

.childForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.childFormControlButtonsCentered {
  justify-content: center;
}

.childFormControlButtonsAligned {
  justify-content: flex-end;
}
