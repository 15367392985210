%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-align-center {
  display: flex;
  align-items: center;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-between {
  display: flex;
  justify-content: space-between;
}

%text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}
