.sortPanel {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background: #f6f6f6;
  padding: 20px;
}

.sortPanelText {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-2);
  margin-right: 17px;
}
