@import '../../../../../assets/styles/medias';
@import '../../../../../assets/styles/mixins';

.testimonialContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;
  height: 670px;
  overflow: hidden;

  @include media_lg {
    flex-direction: row;
    justify-content: flex-start;
    height: 350px;
  }
}

.testimonialImage {
  width: 350px;
  height: 350px;
  object-fit: cover;
}

.testimonialInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 30px;
  margin-top: auto;

  @include media_lg {
    margin-top: 0;
  }
}

.testimonialQuoteImage {
  width: 70px;
  height: 43px;
  object-fit: contain;
  margin-bottom: 25px;
}

.testimonialTitle {
  font-size: 27px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--white);
  margin-bottom: 17px;

  @include media_ss {
    font-size: 29px;
  }

  @include media_lg {
    font-size: 39px;
  }
}

.testimonialText {
  font-size: 16px;
  line-height: 30px;
  color: var(--white);
  margin-bottom: 25px;
  overflow: hidden;
  @include box-orient-vertical(3);

  @include media_lg {
    font-size: 20px;
  }
}

.testimonialDivider {
  width: 80px;
  height: 4px;
  margin-bottom: 20px;
  background-color: var(--white);
  border-radius: 2px;
}

.testimonialAuthor {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--white);
}
