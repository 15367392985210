@import '../../../assets/styles/medias';

.recommendedBookIcon {
  width: 18px;
  height: 20px;
  flex-shrink: 0;

  @include media_md {
    width: 30px;
    height: 37px;
  }
}

.booksCarousel {
  margin: 45px auto 0;

  @include media_md {
    margin: 45px auto 0 0;
  }

  @include media_lg {
    margin-top: 58px;
  }
}
