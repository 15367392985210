@import '../../../../assets/styles/medias';
@import '../../../../assets/styles/placeholders';

.progressModal {
  max-width: 640px;
  width: 100%;
}

.progressModalContent {
  position: relative;
  @extend %flex-column;
  width: 100%;
  max-width: 640px;
  padding: 30px;
  background-color: var(--white);

  @include media_md {
    padding: 60px;
  }
}

.progressModalTitleAndDescriptionWrapper {
  @extend %flex-column;
  gap: 8px;
  text-align: center;
  margin-bottom: 30px;
}

.progressModalTitle {
  font-size: 25px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-2);
  text-align: center;

  @include media_sm {
    font-size: 30px;
  }
}

.progressModalBtnsContainer {
  @extend %flex-column;
  align-items: center;
  gap: 10px;

  @include media_sm {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.progressModalBtn {
  font-size: 16px;
  line-height: 1.2;
  padding: 12px 10px;
  min-width: 235px;
  width: fit-content;

  @include media_xl {
    padding: 15px 14px;
  }
}
