@import '../../../assets/styles/placeholders';

.error {
  @extend %flex-column;
  width: 100%;
}

.errorContent {
  @extend %flex-column;
  align-items: center;
  gap: 10px;
  margin: 120px 0 120px;
}

.errorText {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: var(--color-2);
  margin-bottom: 16px;
}

.errorImageWrapper {
  width: 200px;
  height: 200px;
  margin-bottom: 12px;

  img {
    width: 100%;
    object-fit: contain;
  }
}
