@import '../../assets/styles/placeholders';
@import '../../assets/styles/mixins';

.recommendationCard {
  @extend %flex-column;
  height: 100%;
  width: 300px;
  max-width: 300px;
  overflow: hidden;
  cursor: pointer;
}

.recommendationCardContent {
  @extend %flex-column;
  position: relative;
  width: 100%;
  height: 100%;
}

.recommendationCardPriceAndImageWrapper {
  position: relative;
  width: 100%;
  height: 220px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.recommendationCardPriceWrapper {
  @extend %flex-column;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: center;
}

.recommendationCardOldPrice,
.recommendationCardDiscountPrice {
  @extend %flex-center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--white);
  padding: 6px;
}

.recommendationCardOldPriceCrossedOut {
  text-decoration: line-through;
}

.recommendationCardOldPrice {
  background: var(--color-7);
  border: 1px solid var(--color-7);
}

.recommendationCardDiscountPrice {
  background: #4acc86;
  border: 1px solid var(--color-1);
}

.recommendationCardCurriculumInfo {
  @extend %flex-column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  visibility: hidden;
  top: 20px;
  left: 0;
  right: 0;
  height: 200px;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  padding: 25px 15px 15px;
  opacity: 0;
  transition: all 0.3s ease-out;
  overflow: hidden;
}

.recommendationCardCurriculumList {
  @extend %flex-column;
  justify-content: flex-start;
  width: 100%;
  max-height: 120px;
  overflow: hidden;
}

.recommendationCardCurriculumItem {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    background-color: #12c1bd;
    border-radius: 50%;
  }
}

.recommendationCardCurriculumInfo:hover,
.recommendationCardPriceAndImageWrapper:hover + .recommendationCardCurriculumInfo {
  visibility: visible;
  opacity: 1;
}

.recommendationCardInfo {
  @extend %flex-column;
  padding: 16px;
  border: 1px solid var(--color-border);
  flex-grow: 1;
}

.recommendationCardSubjectAndBtnsWrapper {
  @extend %flex-between;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 12px;
}

.recommendationCardSubjectLink {
  @extend %flex-center;
  @extend %text-ellipsis;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.08px;
  color: var(--white);
  background: #4acc86;
  width: fit-content;
  max-width: 200px;
  padding: 6px 10px;
  white-space: nowrap;

  &:hover {
    color: var(--white);
  }
}

.recommendationCardBtnsWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: center;
}

.recommendationCardFavoriteBtn,
.recommendationCardSaveBtn {
  @extend %flex-center;

  svg {
    width: 20px;
    height: 20px;
  }
}

.recommendationCardSaveBtn {
  &:hover {
    color: #4acc86;
  }
}

.saveIconColored {
  path {
    fill: var(--color-1);
    stroke: var(--color-1);
  }
}

.recommendationCardTitle {
  @extend %text-ellipsis;
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: var(--color-2);
  margin-bottom: 16px;
  @include box-orient-vertical(2);
  max-width: 265px;
  cursor: pointer;
  flex-grow: 1;

  &:hover {
    color: var(--color-1);
  }
}

.recommendationCardAuthorAndAgeInfo {
  @extend %flex-between;
  align-items: flex-start;
  gap: 6px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 600;

  a {
    flex-shrink: 0;

    &:hover {
      transition: none;
      color: var(--color-1);
    }
  }
}

.recommendationCardCartBtn {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  padding: 10px 23px;
  align-self: flex-end;
}

.curriculumSummary {
  margin-bottom: 16px;
}

.recommendationCardFooterWrapper {
  @extend %flex-column;
  justify-content: space-between;
}
