@import '../../../../assets/styles/medias';

.product-description {
  &__description__text {
    font-size: 16px;
    line-height: 1.2;
    color: var(--black);
  }

  @include media_xl {
    font-size: 20px;
  }

  &__attention-block {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 60px 27px;
    background-color: var(--color-teal-green);
    margin: 35px 0 30px;

    &::before {
      display: inline-block;
      content: url('../../../../assets/img/exclamation-mark.png');
      width: 17px;
      height: 70px;
    }

    @include media_xl {
      margin: 27px 0 50px;
      padding: 50px;
      gap: 30px;
    }
  }

  &__attention-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    color: var(--white);
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
    transition: none;

    &:hover {
      color: var(--color-1);
      text-decoration: underline;
    }
  }

  ul,
  ol {
    padding: 0 10px 10px 15px;
  }

  ul {
    list-style: inside;
  }

  ol {
    margin-left: 15px;
  }
}
