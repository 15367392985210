@import '../../../../../assets/styles/medias';
$base-width: 255px;
$base-gap: 20px;

.booksCarouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_xl {
    display: none;
  }

  :global(.slick-slider) {
    width: $base-width;

    @include media_custom_min_width(600px) {
      width: ($base-width * 2) + $base-gap;
    }

    @include media_lg {
      width: ($base-width * 3) + ($base-gap * 2);
    }
  }

  :global(.slick-track) {
    display: flex !important;
    justify-content: center;
    gap: $base-gap;
  }
}
