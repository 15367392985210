.newsletterDescription {
  margin-top: -6px;
  margin-bottom: 26px;
  color: var(--white);
  font-size: 16px;
}

.formInputWrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 25px;
}

.formInput {
  padding: 0 35px;
  width: 100%;
  height: 60px;
  background: var(--gradient-5);
  color: var(--white);

  &:focus,
  &:focus-visible {
    outline: none;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: var(--white) !important;
    -webkit-box-shadow: 0 0 0px 1000px #04c9d3 inset !important;
    transition: background-color 5000s ease-in-out 0s;
    border: 0;
  }

  &::placeholder {
    color: var(--white-2);
  }
}

.newsletterErrorMessage {
  font-size: 15px;
  color: var(--color-error);
}
