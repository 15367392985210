.sidebarBox {
  border-radius: 0;
  border: 1px solid var(--color-border);
  margin-bottom: 50px;
  width: 100%;
}
.sidebarTitleBox {
  border-bottom: 1px solid var(--color-border);
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
}
.sidebarTitle {
  font-size: 24px;
  line-height: 70px;
  font-weight: 500;
  color: var(--color-2);
  margin-bottom: 0px;

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    font-size: 22px;
  }
}

.sidebarBoxBody {
  padding: 30px;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.tagBtn {
  height: 40px;
  line-height: 38px;
  font-size: 16px;
  color: var(--black);
  border: 1px solid #e9e9e9;
  border-radius: 0px;
  position: relative;
  z-index: 2;
  padding: 0 15px 0 30px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    background: var(--gradient-1);
    border-radius: 3px;
    opacity: 0;
    transition: 0.3s;
    z-index: -1;
  }

  @media (hover: hover) {
    &:hover {
      color: var(--white);
      &:after {
        opacity: 1;
      }
    }
  }
}

.selectedTag {
  color: var(--white);
  &:after {
    opacity: 1;
  }
}

.sidebarSearchIcon {
  position: absolute;
  top: 50%;
  left: 7px;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
}
