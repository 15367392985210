.dropdown {
  position: relative;
  width: max-content;
  min-width: 150px;
  background: #f6f6f6;
}

.dropdownSelectedInstance {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-2);
  background-color: var(--white);
  padding: 13px 10px 13px 13px;
  border-radius: 5px;
  cursor: pointer;
}

.dropdownSelectedItem {
  background-color: var(--white);
}

.dropdownIcon {
  transition: all 0.3s;

  path {
    fill: var(--color-2);
  }
}

.dropdownIconRotated {
  transform: rotateX(180deg);
}

.dropdownItemsContainer {
  visibility: visible;
  opacity: 1;
  transition: all 0.2s;
  transform: scale(1);
  transform-origin: top right;
  position: absolute;
  z-index: 10;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-2);
  background-color: var(--white);
  border-radius: 5px;
  margin-top: 4px;
  box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
}

.dropdownItem {
  padding: 13px;
  cursor: pointer;

  &:hover {
    background-color: #f6f6f6;
  }
}

.dropdownItemSelected {
  font-weight: 600;
  background-color: #f6f6f6;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  transform: scale(0.75);
}
