@import '../../assets/styles/medias';

.banner {
  background: url(../../assets/img/preload-banner-background-mobile.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 190px;
  padding-bottom: 200px;

  @include media_custom_min_width(1024px) {
    background-position: 60%;
    background-image: url(../../assets/img/preload-banner-background.webp);
  }

  @include media_custom_min_width(2200px) {
    background-position: center;
    background-size: 1920px;
  }
}

.bannerBreadcrumbTitle {
  font-size: 40px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--white);
  margin-bottom: 15px;

  @include media_ss {
    font-size: 50px;
  }

  @include media_sm {
    font-size: 55px;
  }

  @include media_md {
    font-size: 60px;
  }

  @include media_lg {
    font-size: 72px;
  }
}

.bannerLink {
  font-size: 20px;
  font-weight: 700;
  color: var(--white);

  &:hover {
    color: var(--color-1);
  }
}

.bannerDevider {
  display: inline-block;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 300;
  color: var(--white);
  line-height: 20px;
}

.bannerSubtitle {
  font-size: 20px;
  color: var(--white);
}

.breadcrumbsContainer {
  display: inline-flex;
  align-items: center;

  a {
    font-size: 20px;
    color: var(--white);

    &:hover {
      color: var(--color-1);
    }
  }

  span {
    margin-top: 2px;
  }
}
