@import '../../assets/styles/medias';

.carouselVisiblity {
  @include media_xl {
    :global(.slick-slide) {
      visibility: hidden;
    }

    :global(.slick-slide.slick-active.slick-current) {
      visibility: visible;
    }

    :global(.slick-slide.slick-active) {
      visibility: visible;
    }
  }
}
