@import '../../../../assets/styles/medias';
@import '../../../../assets/styles/placeholders';

.modal {
  padding: 40px 20px;
  @extend %flex-justify-center;
  flex-direction: column;
  max-width: 633px;
  width: 100%;
  background-color: white;

  @include media_lg {
    padding: 40px 24px 40px 50px;
    width: 633px;
  }
}

.modalTitle {
  @extend %flex-justify-center;
  width: 100%;
  font-weight: 800;
  font-size: 36px;
  line-height: 42px;
  text-align: right;

  color: #00394f;
}

.modalSubtitle {
  @extend %flex-justify-center;
  margin-top: 15px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  color: #717171;
}

.selects {
  margin-top: 14px;
  @extend %flex-justify-center;
}

.select {
  cursor: pointer;
  border: 1px solid #717171;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: #00394f;
  padding: 19px 20px;
  height: 59px;
  max-width: 144px;
  width: 100%;
  margin-right: 5px;
  @extend %flex-justify-center;
  align-items: center;
  &:last-child {
    margin-right: 0px;
  }
}

.selected {
  color: #f7fdff;
  background: #74ce49;
}

// checkbox start

.wrapperCheckbox {
  margin-top: 26px;
  display: flex;
}

.textCheckbox {
  width: 100%;
  display: flex;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  color: #717171;
}

.termscheck {
  @extend %flex-align-center;
  justify-content: flex-start;
  width: 100%;

  input {
    display: none;
  }

  label {
    @extend %flex-align-center;
    gap: 15px;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: left;
    color: #717171;

    span {
      position: relative;
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 15px;
      flex: 0 0 15px;
      max-width: 15px;
      width: 100%;
      height: 15px;
      border: 1px solid #e9e9e9;
      background-color: #f3f3f4;
      top: -1px;
      border-radius: 2px;
      &::before {
        position: relative;
        font-family: 'IcoFont';
        font-size: 0.5rem;
        content: '\eed6';
        line-height: 1;
        left: 0.5px;
        opacity: 0;
        font-weight: 900;
        font-size: 10px;
      }
    }
  }
}

.termscheck input[type='checkbox']:checked + label span {
  background-color: #23cc88;
}

.termscheck input[type='checkbox']:checked + label span {
  background-color: #23cc88;
}

.termscheck input[type='checkbox']:checked + label span {
  background-color: #23cc88;
  border-color: transparent;
}

.termscheck input[type='checkbox']:checked + label span::before {
  color: #fff;
  opacity: 1;
}
// checkbox end

.text {
  @extend %flex-align-center;
  gap: 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: left;
  color: #717171;
  margin-top: 14px;
}

.wrapperBtn {
  display: flex;
  justify-content: flex-end;
}

.modalBtn {
  background: linear-gradient(94.68deg, #ff5851 0%, #ffae05 100%);
  margin-top: 31px;
  max-width: 216px;
  width: 100%;
  padding: 15px;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-transform: uppercase;
  color: #ffffff;

  &:hover {
    background: linear-gradient(94.68deg, #ff5851 20%, #ffae05 70%);
  }

  &:active {
    background: linear-gradient(94.68deg, #d14843 20%, #d39614 70%);
  }
}
