@import '../../assets/styles/medias';
@import '../../assets/styles/placeholders';

.product {
  padding: 100px 0 100px;
}

.productSubjectMobile,
.productSubjectDesktop {
  justify-content: flex-start;
  align-items: center;
  width: max-content;
  gap: 8px;
  font-size: 20px;
  line-height: 23px;
  font-weight: 600;
  color: var(--black);
  margin-bottom: 14px;

  &:hover,
  &:focus,
  &:focus-visible {
    color: var(--color-1);

    svg {
      path {
        fill: var(--color-1);
      }
    }
  }

  svg {
    width: 19px;
    height: 19px;

    path {
      fill: var(--black);
    }
  }
}

.productSubjectMobile {
  display: flex;
  margin-left: auto;

  @include media_lg {
    display: none;
  }
}

.productSubjectDesktop {
  display: none;

  @include media_lg {
    display: flex;
    margin-bottom: 10px;
  }

  @include media_xl {
    font-size: 23px;
    line-height: 1.2;
  }
}

.productImageAndInfo {
  @extend %flex-column;

  @include media_lg {
    flex-direction: row;
    gap: 40px;
  }
}

.productImgSliderAndSubject {
  @extend %flex-column;

  .productImageSlider {
    max-width: none;

    & > div:first-child {
      @include media_md {
        height: 412px;
        padding: 16px 24px;
      }

      @include media_lg {
        height: 300px;
        padding: 29px 24px;
      }

      @include media_custom_min_width(1400px) {
        padding: 45px 36px;
        height: 475px;
      }
    }

    @include media_lg {
      max-width: 350px;
    }

    @include media_custom_min_width(1400px) {
      max-width: 576px;
    }
  }

  @include media_lg {
    order: -1;
    flex-shrink: 0;
  }
}

.productAndPriceInfoMobileAndTablet {
  @include media_lg {
    display: none;
  }
}

.productInfoContent {
  @include media_lg {
    flex-grow: 1;
  }
}

.productAndPriceInfoDesktop {
  display: none;

  @include media_lg {
    display: block;
  }
}

.productDescription {
  font-size: 16px;
  font-size: 16px;
  color: var(--describtion-color);
  margin-top: 24px;
  margin-bottom: 24px;

  @include media_custom_min_width(1400px) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 40px;
    margin-bottom: 28px;
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
    transition: none;

    &:hover {
      color: var(--color-1);
      text-decoration: underline;
    }
  }
}

.productButtonsContainer {
  @extend %flex-column;
  align-items: flex-start;
  margin-bottom: 46px;
  gap: 16px;
  width: fit-content;

  @include media_md {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
  }

  @include media_xl {
    margin-bottom: 35px;
    gap: 30px;
  }
}

.productBtn {
  width: 100%;

  @include media_md {
    width: unset;
  }
}

.productBtnAlt {
  width: 100%;

  @include media_md {
    width: unset;
  }
}

.productFavoriteAndSaveButtonsContainer {
  @extend %flex-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  padding: 0 12px;
  margin-bottom: 28px;

  @include media_md {
    gap: 20px;
  }
}

.productFavoriteBtn {
  svg {
    width: 25px;
    height: 22px;
  }
}

.productSaveBtn {
  svg {
    width: 19px;
    height: 23px;
  }
}

.shareBtn {
  svg {
    width: 23px;
    height: 23px;
  }
}

.saveIconColored {
  path {
    fill: var(--color-1);
    stroke: var(--color-1);
  }
}

.productSaveBtn,
.productFavoriteBtn,
.shareBtn {
  @extend %flex-between;
  align-items: center;
  gap: 7px;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);

  &:hover {
    color: var(--color-1);
  }
}

.productPopularTags {
  margin-bottom: 23px;
  max-width: 100%;
}

.recommendedMaterials {
  @extend %flex-column;
  justify-content: center;
  margin-top: 50px;
  gap: 40px;
}

.productBtnDisabled {
  background: var(--light-gray);
  box-shadow: none;

  &::after {
    background: none;
  }
}
