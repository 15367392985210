@import '../../../../../assets/styles/medias';
$base-width: 350px;
$base-gap: 20px;

.ourWebinarsCarouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_xl {
    display: none;
  }

  :global(.slick-slider) {
    width: $base-width;

    @include media_md {
      width: ($base-width * 2) + $base-gap;
    }
  }

  :global(.slick-track) {
    display: flex !important;
    justify-content: center;
    gap: $base-gap;
  }
}
