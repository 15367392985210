.modalOverlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  cursor: zoom-out;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}

.modalContent {
  position: relative;
  cursor: auto;
  transform: translateY(70px);
  transition: all 0.3s;
  max-height: 100%;
  overflow-y: auto;
  overscroll-behavior: none !important;
}

.modalCloseBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
}

.modalOverlayVisible {
  opacity: 1;
}

.modalContentVisible {
  transform: translateY(0);
}
