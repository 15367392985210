.footerSocial {
  width: 20px;
  height: 20px;

  &:hover {
    path {
      fill: var(--color-1);
    }
  }
}
