@import '../../assets/styles/medias';
@import '../../assets/styles/placeholders';

.partnersList {
  @extend %flex-justify-center;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;

  @include media_lg {
    gap: 30px;
  }
}

.partner {
  @extend %flex-justify-center;
  flex-direction: column;
  gap: 10px;
  width: 170px;
}

.logo {
  width: 100%;
  height: 95px;
  object-fit: contain;
}

.namePartner {
  font-size: 16px;
  line-height: 19px;
  text-align: center;
}
