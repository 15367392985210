@import '../../assets/styles/medias';
@import '../../assets/styles/placeholders';

.loader {
  @extend %flex-center;
  position: fixed;
  z-index: 20;
  inset: 0;
  background-color: var(--white);
  overscroll-behavior: none !important;
}

.loaderContent {
  @extend %flex-center;
  flex-direction: column;
}

.loaderLogo {
  width: 180px;
  margin-bottom: 20px;
}

.loaderStroke {
  box-sizing: content-box;
  position: relative;
  width: 250px;
  background: var(--color-1);
  height: 3px;
  border: 0.5px solid var(--color-1);

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    background-color: #fff;
    animation: loading 5s linear infinite;
    animation-timing-function: cubic-bezier(0.75, 0.04, 0, 0.86);
  }

  @include media_ss {
    width: 285px;
  }
}

.loaderPen {
  position: absolute;
  top: -60px;
  left: 0;
  width: 60px;
  height: 60px;
  animation: loading 5s linear infinite;
  animation-timing-function: cubic-bezier(0.75, 0.04, 0, 0.86);
}

@keyframes loading {
  50% {
    left: 100%;
  }
  100% {
    left: 0;
  }
}
