@import '../../assets/styles/placeholders';
@import '../../assets/styles/mixins';

.card {
  display: inline-block;
  max-width: 402px;
  width: 100%;
  position: relative;
  cursor: pointer;

  @media (max-width: 1350px) {
    width: 350px;
  }
}

.cardBody {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.cardBodyImg {
  width: 100%;
  height: 260px;

  a {
    display: block;
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 1350px) {
    height: 293px;
  }
}

.wrapperPrice {
  position: absolute;
  top: 25px;
  left: 25px;
  cursor: default;
}

.cardBodyOldPrice {
  justify-content: center;
  align-items: center;
  height: 35px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: white;
  padding: 6px;
  background: #ff4f58;
  border: 1px solid #ff4f58;
  margin-bottom: 7px;
}

.cardPriceCrossedOut {
  text-decoration: line-through;
}

.cardBodyNewPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-weight: 600;
  font-size: 20px;
  line-height: 23px;
  color: white;
  padding: 6px;
  background: #4acc86;
  border: 1px solid #23cc88;
}

.cardBodyTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  color: #00394f;
  width: 100%;
  margin-bottom: 18px;
  @include box-orient-vertical(2);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-width: 300px;

  &:hover {
    color: #23cc88;
  }
}

.wrapperTitleAndOther {
  @extend %flex-between;
  flex-direction: column;
  padding: 31px 11px 25px 25px;
  border: 1px solid var(--color-border);
  flex-grow: 1;
}

.wrapperBtnAndCollectionAndFavorite {
  @extend %flex-between;
  gap: 16px;
  align-items: flex-start;
  margin-bottom: 16px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08px;
  color: #ffffff;
  background: #4acc86;
  width: fit-content;
  padding: 6.5px 11px;

  &:hover {
    color: #fff;
  }
}

.wrapperSaveAndFavoriteIcons {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: center;
}

.wrapperCollection {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    font-size: 14px;
  }
  &:hover {
    color: #4acc86;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.saveIconColored {
  path {
    fill: var(--color-1);
    stroke: var(--color-1);
  }
}

.wrapperFavoriteIcon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  svg {
    width: 25px;
    height: 25px;
  }
}

.courseMaterials {
  display: flex;
  flex-wrap: wrap;

  .courseMaterialText {
    font-size: 16px;
    line-height: 19px;
  }

  .courseMaterialCircle {
    width: 12px;
    height: 12px;
  }
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #12c1bd;
  margin-right: 5.5px;
}

.wrapperAuthorAndYears {
  @extend %flex-between;
  margin-top: 16px;
}

.author {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #717171;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 210px;
}

.wrapperYears,
.years {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
}

.wrapperYears {
  display: flex;
  &:hover {
    color: var(--color-1);
  }
}

.years {
  margin-left: 5px;
  color: var(--color-1);
}

.bookCardCurriculumInfo {
  visibility: hidden;
  position: absolute;
  z-index: 0;
  top: 60px;
  left: 0;
  width: 100%;
  height: 235px;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  padding: 30px 10px 20px 18px;
  opacity: 0;
  transition: all 0.3s ease-out;
  overflow: hidden;
  @extend %flex-between;
  flex-direction: column;
  align-items: center;
}

.bookCardCurriculumInfo:hover,
.cardBodyImg:hover + .bookCardCurriculumInfo {
  visibility: visible;
  opacity: 1;
}

.bookCardCurriculumItem {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 5px;

  &::before {
    content: '';
    display: inline-block;
    flex-shrink: 0;
    width: 11px;
    height: 11px;
    margin-right: 6px;
    background-color: #12c1bd;
    border-radius: 50%;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.wrapperThanks {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #4acc86;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.thanksText {
  margin-right: 7px;
}

.wrapperBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.shoppingCardBtn {
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  background: linear-gradient(94.06deg, #ff5553 0.42%, #ffb003 98.97%);
  max-width: 192px;
  width: 100%;
  padding: 13px 44px;

  &:hover {
    background: linear-gradient(10.06deg, #f74744 0.62%, #fbb41c 98.97%);
  }
  &:active {
    background: linear-gradient(170.06deg, #f73734 0.82%, #fcbb2e 98.97%);
  }
}

.bookCardCurriculumList {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-height: 120px;
  overflow: hidden;
}

.cardMoreBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 29px;
  height: 29px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.cardControlPanel {
  top: 40px;
  right: 0;
}
