@import '../../../../../assets/styles/medias';

.footerCardDescription {
  margin-top: -6px;
  margin-bottom: 30px;
  padding-right: 30px;
  color: var(--white);
  max-width: 400px;
}

.footerFollow {
  font-size: 18px;
  line-height: 100%;
  font-weight: 500;
  color: var(--white);
  margin-bottom: 18px;

  @include media_lg {
    margin-bottom: 23px;
  }
}
