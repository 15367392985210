.courseSubjectInfo {
  display: flex;
  align-items: baseline;
  gap: 7px;
  margin-right: 25px;
}

.courseSubjectCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #12c1bd;
  flex-shrink: 0;
}

.courseSubjectAmount {
  font-size: 20px;
  line-height: 23px;
  color: var(--black);

  span {
    font-weight: 600;
  }
}

.courseSubjectInfoWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 6px;
}
