@import '../../../../../assets/styles/medias';

.openCollectionIcon {
  width: 30px;
  height: 30px;

  path {
    fill: var(--color-2);
  }
}

.openCollectionTxt {
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  margin-bottom: 20px;
  text-align: center;
}

.openCollectionButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @include media_sm {
    flex-direction: row;
    justify-content: center;
    gap: 65px;
  }
}

.openCollectionButton {
  padding: 10px;
  min-width: 216px;

  @include media_sm {
    padding: 20px;
  }
}
