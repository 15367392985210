@import '../../../../assets/styles/medias';

.formButtons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 20px;
}

.childFormDeleteBtn,
.formEditButton,
.formCancelButton,
.formSaveButton {
  padding: 12px;
  width: fit-content;
}

.childFormDeleteBtn {
  background: #ff0000;
  width: fit-content;
  box-shadow: none;

  &:after {
    display: none;
  }
}
