.createCollectionFormInputWrapper {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.createCollectionFormInput {
  font-size: 18px;
  line-height: 21px;
  padding: 12px 20px;
  border: 1px solid var(--color-border);

  &:focus {
    outline: none;
    border-color: var(--color-1);
    color: var(--color-1);
  }
}

.createCollectionFormInput::placeholder {
  color: var(--black);
}

.error {
  font-size: 14px;
  color: var(--color-error);
}
