@import '../../../assets/styles/medias';

.comment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 14px 8px 27px 16px;
  border: 1px solid var(--color-border);

  @include media_sm {
    flex-direction: row;
    justify-content: flex-start;
    gap: 24px;
  }

  @include media_xl {
    padding: 36px 110px 45px 40px;
  }
}

.commentIndent {
  margin-left: 50px;

  @include media_xl {
    margin-left: 120px;
  }
}

.commentAuthor {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: var(--color-1);
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  color: var(--white);
  margin-bottom: 12px;
  flex-shrink: 0;

  @include media_md {
    margin-bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.commentInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex-grow: 1;
  width: 100%;
  max-width: 90%;
  overflow: hidden;

  @include media_sm {
    max-width: 75%;
  }

  @include media_lg {
    max-width: 90%;
  }
}

.commentAuthorNames {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  max-width: 100%;

  @include media_xl {
    gap: 24px;
  }
}

.commentAuthorName {
  font-size: 18px;
  line-height: 21px;
  font-weight: 600;
  color: var(--color-2);
  margin-bottom: 12px;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media_lg {
    margin-bottom: 20px;
  }
}

.commentText {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: var(--black);
  margin-bottom: 12px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media_lg {
    margin-bottom: 14px;
  }
}

.commentControl {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  @include media_md {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
}

.commentReply {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @include media_lg {
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }
}

.commentDate,
.commentReplyButton {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: var(--black);

  @include media_lg {
    font-size: 16px;
    line-height: 19px;
  }
}

.commentReplyButton {
  margin-bottom: 12px;

  &:hover {
    color: var(--color-1);
  }

  @include media_lg {
    margin-bottom: 0;
  }

  @include media_lg {
    font-size: 18px;
    line-height: 21px;
  }
}

.commentClockIcon,
.commentReplyIcon {
  margin-right: 5px;
  width: 16px;
  height: 16px;
}

.commentControlButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--black);

  @include media_lg {
    font-size: 18px;
    line-height: 21px;
  }
}

.commentArrowIcon {
  width: 13px;
  height: 13px;
}

.commentArrowIconRotate {
  transform: rotate(180deg);
}

.nestedCommentsControl {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 20px;
}

.nestedCommentAuthorImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-1);
  border-radius: 50%;
  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
}

.nestedCommentsAmount {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--color-2);

  &::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #070707;
    margin-right: 8px;
  }
}
