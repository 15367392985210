@import '../../../../../assets/styles/medias';

.item {
  font-size: 15px;
  font-weight: 700;
  line-height: 23px;
  color: var(--color-2);
  text-transform: uppercase;
  padding: 6px;
  cursor: pointer;

  &:hover,
  &:focus-visible,
  &:focus-within {
    color: var(--color-1);

    .sublist {
      color: var(--color-2);
      opacity: 1;
      visibility: visible;
      top: 100%;
    }
  }

  @include media_xl {
    font-size: 16px;
    line-height: 24px;
  }

  @include media_custom_min_width(1400px) {
    padding: 13px;
  }
}

.sublist {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 240px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  border: 0;
  top: 120%;
  background-color: #fff;
  border-top: 4px solid #23cc88;
  box-shadow: 0 13px 29px rgba(0, 0, 0, 0.1);
  padding: 15px 25px;
  margin-left: -12px;
}

.sublistItem {
  white-space: nowrap;

  &:hover {
    color: var(--color-1);
    letter-spacing: 1px;
  }
}

.arrow::after {
  content: '\eab2';
  font-family: 'IcoFont';
  font-size: 16px;
  font-weight: 700;
  margin-left: 5px;
}
