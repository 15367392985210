@import '../../../../assets/styles/medias';

.ourPostsContentDesktop {
  display: none;

  @include media_xl {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    justify-content: center;
    justify-items: center;
    gap: 20px;
  }

  @include media_custom_min_width(1300px) {
    grid-template-columns: repeat(auto-fit, 412px);
  }
}
