@import '../../../../assets/styles/medias';

.counter {
  background: url('../../../../assets/img/counter-bg.webp') center center no-repeat;
  background-size: cover;
  padding-top: 120px;
  padding-bottom: 120px;
}

.counterHeading {
  margin-bottom: 70px;
  text-align: center;
}

.counterTitle {
  font-size: 29px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -8px;
  color: var(--white);

  @include media_ss {
    font-size: 36px;
  }

  @include media_sm {
    font-size: 38px;
  }

  @include media_lg {
    font-size: 40px;
  }

  @include media_xl {
    font-size: 52px;
  }
}

.counterDescription {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
  margin-bottom: -8px;
}

.singleCounter {
  padding: 15px;
  flex: 0 0 auto;
  width: 50%;

  @include media_md {
    width: 25%;
  }
}

.counterBox {
  display: flex;
  align-items: center;
  flex-direction: column;

  &:hover {
    img {
      animation-name: wobble-vertical;
      animation-duration: 1s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: 1;
    }
  }
}

.counterImg {
  width: 116px;
  margin-bottom: -35px;

  @include media_md {
    width: 150px;
    margin-bottom: -45px;
  }

  @include media_xl {
    margin-bottom: -60px;
    width: 205px;
  }
}

.counterPartTxt {
  color: var(--white);
  position: relative;
  text-align: center;
}

.counterTxt {
  font-size: 39px;
  font-weight: 600;
  line-height: 100%;
  margin-bottom: 10px;
  color: var(--white);

  @include media_sm {
    font-size: 40px;
  }

  @include media_xl {
    font-size: 52px;
  }
}

.counterAccomplishmentTitle {
  font-size: 20px;
  color: var(--white);
}

@keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
