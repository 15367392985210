@mixin box-orient-vertical($line-clamp) {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  -webkit-line-clamp: $line-clamp;
  -moz-line-clamp: $line-clamp;
  -ms-line-clamp: $line-clamp;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
}
