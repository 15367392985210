@import '../../../assets/styles/medias';

.mobileCommonTitle {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-2);
}

.videoCourseIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  @include media_md {
    width: 35px;
    height: 30px;
  }
}

.videoCoursesCarousel {
  margin: 0 auto;

  @include media_md {
    margin: 0 auto 0 0;
  }
}
