.collectionsItem {
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  color: var(--black);
}

.collectionsItemBtn {
  display: flex;
  align-items: center;
  gap: 13px;
  position: relative;
  transition: none;

  &::before {
    content: '\eed6';
    width: 19px;
    height: 19px;
    border: 1px solid var(--color-border);
    flex-shrink: 0;
    font-family: 'IcoFont';
    color: var(--white);
    font-weight: 900;
    font-size: 18px;
    line-height: 1;
  }

  &:hover {
    color: var(--color-1);
  }
}

.collectionsItemBtnChecked {
  &::before {
    background-color: var(--color-1);
  }
}
