@import '../../../../../assets/styles/medias';
@import '../../../../../assets/styles/placeholders';

.postCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;

  @include media_custom_min_width(1300px) {
    width: 412px;
  }
}

.postCardDate {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  z-index: 2;
  top: 20px;
  left: 20px;
  font-size: 16px;
  line-height: 1.2;
  background-color: var(--color-1);
  padding: 10px;
  color: var(--white);
}

.postCardImgContainer {
  position: relative;
  padding-top: 56.25%;

  a {
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.postCardContent {
  @extend %flex-between;
  flex-direction: column;
  border: 1px solid var(--color-border);
  flex-grow: 1;
}

.postCardInfo {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 30px 30px 10px;
}

.postCardTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--color-2);
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;

  &:hover {
    color: var(--color-1);
  }
}

.postCardDescription {
  font-size: 16px;
  line-height: 1.6;
  color: var(--black);
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
}

.postCardTitle,
.postCardDescription {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
}

.postCardFooter {
  @extend %flex-between;
  align-items: center;
  gap: 12px;
  padding: 20px 30px;
}

.postCardViews {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
}

.postCardViewIcon {
  margin-right: 6px;
  width: 28px;
  height: 28px;
}

.postCardAuthorName {
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
