@import '../../../assets/styles/medias';
@import '../../../assets/styles/placeholders';

.mainFooter {
  padding: 100px 0 70px;
}

.copyrightAndDeveloperInfo {
  @extend %flex-column;
  justify-content: center;
  margin-top: 15px;
  gap: 15px;

  @include media_md {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.copyrightInfoContent {
  @extend %flex-column;
  gap: 6px;
  max-width: 500px;
}

.developerInfo,
.copyrightInfo {
  font-size: 16px;
  line-height: 26px;
  color: var(--white);
}

.developerInfo {
  a {
    color: var(--color-1);
    font-weight: 500;
  }
}
