@import '../../../../assets/styles/medias';

.about {
  color: var(--white);
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: url('../../../../assets/img/preload-home-benefits-mobile.webp');
  background-repeat: no-repeat;
  background-size: cover;

  @include media_md {
    background-image: url('../../../../assets/img/home-benefits-tablet.webp');
  }

  @include media_lg {
    background-image: url('../../../../assets/img/home-benefits-horizontal-tablet.webp');
  }

  @include media_custom_min_width(1300px) {
    padding-top: 70px;
    padding-bottom: 70px;
    background-position: 45%;
    background-image: url('../../../../assets/img/preload-home-benefits-laptop.webp');
  }

  @include media_custom_min_width(1700px) {
    background-image: url('../../../../assets/img/preload-home-benefits-desktop.webp');
  }

  @include media_custom_min_width(1900px) {
    background-position: center;
  }

  @include media_custom_min_width(2200px) {
    background-size: 1920px;
  }
}

.aboutTitle {
  font-size: 29px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 25px;
  margin-top: -8px;

  @include media_ss {
    font-size: 36px;
  }

  @include media_sm {
    font-size: 38px;
  }

  @include media_md {
    font-size: 40px;
  }

  @include media_lg {
    font-size: 52px;
  }
}

.aboutDescription {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 12px;
}

.aboutBtnsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 50px;
}

.aboutBtn {
  min-width: 255px;
}

.aboutSubtitle {
  margin-bottom: 5px;
}
