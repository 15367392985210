@import '../../assets/styles/medias';

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  gap: 30px;
  order: 1;

  @include media_sm {
    max-width: 540px;
  }

  @include media_xl {
    max-width: 400px;
    order: 0;
    margin: 0;
  }
}
