@import '../../../../../assets/styles/medias';

.item {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: var(--white);
  text-transform: uppercase;
}

.arrow::after {
  content: '\eab2';
  position: absolute;
  right: 0;
  top: 0;
  font-family: 'IcoFont';
  font-size: 16px;
  font-weight: 600;
}

.arrowRotated::after {
  transform: rotateX(180deg);
}

.sublist {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  padding: 15px;
}
