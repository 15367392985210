@import '../../../../assets/styles/medias';
@import '../../../../assets/styles/placeholders';

.popularCourse {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.popularCourseImageContainer {
  width: 75px;
  height: 83px;
  flex-shrink: 0;
}

.popularCourseImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popularCourseInfo {
  @extend %flex-between;
  flex-direction: column;
  flex-grow: 1;
  min-height: 83px;
  gap: 3px;
}

.popularCourseTitleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
}

.popularCourseTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-2);
  margin: 0;
  max-width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: var(--color-1);
  }

  @include media_sm {
    max-width: 210px;
    font-size: 18px;
  }
}

.popularCourseMaterialInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 11px;
  gap: 3px;
}

.popularCourseMaterialContainer {
  gap: 4px;
  margin-right: 0;
}

.popularCourseMaterialText {
  font-size: 15px;
  line-height: 18px;
  font-weight: 400;
  color: var(--black);
}

.popularCourseMaterialCircle {
  width: 11px;
  height: 11px;
}

.popularCourseSubjectAndAgeInfo {
  @extend %flex-between;
  align-items: center;
  gap: 12px;
}

.popularCourseSubjectTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--color-2);
  transition: none;
}

.popularCourseAgeInfo {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--black);
  flex-shrink: 0;
  transition: none;
}
