@import '../../assets/styles/medias';

.container {
  width: 100%;
  padding: 0 12px;
  margin: auto;

  @include media_sm {
    max-width: 540px;
  }

  @include media_md {
    max-width: 720px;
  }

  @include media_lg {
    max-width: 960px;
  }

  @include media_xl {
    max-width: 1140px;
  }

  @include media_custom_min_width(1400px) {
    max-width: 1320px;
  }
}
