@import '../../assets/styles/placeholders';
@import '../../assets/styles/mixins';

.bookCard {
  position: relative;
  width: 255px;
  height: 270px;
  overflow: hidden;
  cursor: pointer;
}

.bookCardHeader {
  @extend %flex-between;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 40px;
  background: var(--color-teal-green);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--white);
  padding-left: 10px;

  a:hover {
    color: var(--color-2);
  }
}

.bookCardMoreBtn {
  width: 29px;
  height: 29px;

  svg {
    width: 100%;
    height: 100%;
  }
}

.bookCardControlPanel {
  top: 105%;
  right: 0;
}

.bookCardImage {
  margin-top: -47px;
  width: 255px;
  height: 200px;
  cursor: pointer;
  object-fit: cover;
}

.bookCardFooter {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  padding: 5px 12px 10px 9px;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  height: 97px;
  @extend %flex-column;
  justify-content: space-between;

  a {
    display: flex;
    align-items: center;
  }
}

.bookCardTitleContainer {
  @extend %flex-column;
  justify-content: center;
  flex-grow: 1;
}

.bookCardFooterTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  color: var(--color-2);
  margin: 0;
  max-width: 80%;
  display: flex;
  overflow: hidden;
  @include box-orient-vertical(2);

  &:hover {
    color: var(--color-1);
  }
}

.bookCardPrices {
  position: absolute;
  top: 0;
  right: 0;
  width: max-content;
}

.bookCardDiscountPrice {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  background: #4acc86;
}

.bookCardPrice,
.bookCardDiscountPrice {
  @extend %flex-center;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--white);
  padding: 5px 8px;
  width: 100%;
  min-width: 50px;
}

.bookCardPrice {
  background-color: #f54d55;
}

.bookCardPriceCrossedOut {
  text-decoration: line-through;
}

.bookCardInfo {
  @extend %flex-between;
  align-items: center;
}

.bookCardAgeInfo {
  align-self: flex-end;
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  color: var(--black);
  margin: 0;
}

.bookCardIconsContainer {
  @extend %flex-center;
  gap: 6px;
}

.bookCardIconBtn {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid black;
  }
}

.bookCardSaveIconColored {
  path {
    fill: var(--color-1);
    stroke: var(--color-1);
  }
}

.bookCardCurriculumInfo {
  visibility: hidden;
  @extend %flex-column;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 255px;
  height: 235px;
  background-color: var(--white);
  border: 1px solid var(--color-border);
  padding: 24px 9px 10px 18px;
  transform: translateY(110%);
  opacity: 0;
  transition: all 0.3s ease-out;
}

.bookCardCurriculumInfoContainer {
  width: 100%;
}

.bookCardCurriculumInfo:hover,
.bookCardImage:hover ~ .bookCardCurriculumInfo {
  visibility: visible;
  transform: translateY(0);
  opacity: 1;
}

.bookCardCurriculumList {
  max-height: 120px;
  overflow: hidden;
  align-self: flex-start;
  list-style: url('../../assets/svg/list-circle-mark.svg');
  padding: 0 9px 0 18px;
  margin-bottom: 16px;
}

.bookCardCurriculumItem {
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: var(--black);
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 5px;
  }
}

.bookCardCurriculumAuthor {
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: var(--black);
  text-align: right;
  margin-bottom: 10px;
}

.bookCardGratitude {
  font-size: 14px;
  font-weight: 800;
  line-height: 16px;
  color: var(--color-1);
  text-align: right;

  span {
    text-transform: uppercase;
  }
}

.bookCardCurriculumInfo .bookCardShoppingCartBtn {
  font-size: 14px;
  line-height: 16px;
  font-weight: 800;
  padding: 10px 23px;
}

.ages {
  &:hover {
    p {
      color: var(--color-1);
    }
  }
}
