@import '../../../assets/styles/medias';
@import '../../../assets/styles/placeholders';
$desktop-width: 1400px;

.productInfoContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;

  @include media_lg {
    margin-bottom: 0;
    gap: 30px;
  }
}

.productTitleAndPrice {
  @extend %flex-between;
  align-items: flex-start;
  gap: 15px;

  @include media_lg {
    margin-bottom: 0;
    gap: 20px;
  }
}

.productTitle {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.1;
  color: var(--color-2);

  @include media_lg {
    font-size: 36px;
  }

  @include media_custom_min_width($desktop-width) {
    font-size: 45px;
  }
}

.productPrices {
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.productDiscountPrice,
.productPrice {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 9px;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: var(--white);

  @include media_custom_min_width($desktop-width) {
    font-size: 25px;
    line-height: 1.2;
    padding: 10px 15px;
  }
}

.productPrice {
  background-color: #ff4f58;
}

.productPriceCrossedOut {
  text-decoration: line-through;
}

.productDiscountPrice {
  background-color: var(--color-1);
}

.productAuthorAndAgeInfo {
  @extend %flex-between;
  align-items: center;
  gap: 10px;
}

.productAuthor {
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  color: var(--black);
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 260px;

  @include media_custom_min_width($desktop-width) {
    font-size: 24px;
    line-height: 1.2;
    max-width: 415px;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    color: var(--color-1);
  }
}

.productYears {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--black);
  flex-shrink: 0;

  @include media_custom_min_width($desktop-width) {
    font-size: 24px;
  }
}
