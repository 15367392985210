.modalContentClassName {
  overflow: visible;
  background-color: white;
}

.modalCertificateContainer {
  max-height: 100%;
  max-width: 1200px;

  img {
    max-height: 90vh;
    max-width: 100%;
    object-fit: contain;
  }
}

.modalMain {
  background-color: white;
  width: 599px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  text-align: center;
  font-size: 18px;

  h2 {
    color: var(--color-2);
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;

    button {
      width: fit-content;
    }
  }
}
