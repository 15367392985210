.taskBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  width: 100%;
  overflow: hidden;
}

.taskInfo {
  display: flex;
  align-items: center;
  gap: 7px;
  max-width: 260px;
  overflow: hidden;
}

.taskCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #12c1bd;
  flex-shrink: 0;
}

.taskTitle {
  display: flex;
  align-items: baseline;
  gap: 7px;
  color: var(--black);
  max-width: 93%;

  p, strong {
    font-size: 16px;
    line-height: 19px;
  }

  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
