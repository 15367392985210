@import '../../../../assets/styles/medias';

.ourCoursesContentDesktop {
  display: none;

  @include media_xl {
    display: grid;
    grid-template-columns: repeat(auto-fit, 350px);
    justify-content: center;
    justify-items: center;
    gap: 30px;
  }

  @include media_custom_min_width(1351px) {
    grid-template-columns: repeat(auto-fit, 402px);
    gap: 45px;
  }
}
