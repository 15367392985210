@import '../../../assets/styles/medias';

.search {
  width: 120px;
  height: 38px;
  background: linear-gradient(130deg, #23cc88, #8ecf35);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 0 15px 0 15px;

  i {
    flex-shrink: 0;
    font-size: 14px;
    color: var(--white);
    font-weight: bold;
    margin-left: 5px;

    @include media_xl {
      font-size: 18px;
      margin-left: 16px;
    }
  }

  input {
    width: 100%;
    background-color: transparent;
    color: white;
    border: none;

    &:focus-visible,
    &:focus {
      outline: none;
    }

    &::placeholder {
      font-style: normal;
      font-size: 14px;
      font-weight: 700;
      line-height: 17px;
      color: var(--white);

      @include media_xl {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }

  @include media_custom(1024px, 1200px) {
    width: 100px;
    padding: 0 5px 0 5px;
  }

  @include media_xl {
    width: 160px;
  }

  @include media_custom_min_width(1200px) {
    height: 60px;
    margin-left: 10px;
  }
}
