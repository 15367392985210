.carouselArrowRightBtn,
.carouselArrowLeftBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background: var(--gradient-1);
  position: absolute;
  z-index: 1;

  &::after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-2);
    opacity: 0;
    transition: all 0.3s ease-in;
    z-index: -1;
  }

  &:hover {
    &::after {
      opacity: 1;
    }
  }
}

.carouselArrowRightBtn {
  top: 40%;
  right: 0;
  transform: translateX(50%);
}

.carouselArrowLeftBtn {
  top: 40%;
  left: 0;
  transform: translateX(-50%);
}

.carouselArrowRightIcon {
  transform: rotate(-90deg);
  width: 100%;
  height: 100%;

  path {
    fill: var(--white);
  }
}

.carouselArrowLeftIcon {
  transform: rotate(90deg);
  width: 100%;
  height: 100%;

  path {
    fill: var(--white);
  }
}
