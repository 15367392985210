@import '../../../../assets/styles/medias';

.youTube {
  background: url('../../../../assets/img/you-tube-home-banner.webp') center center no-repeat;
  background-size: cover;
  padding-bottom: 120px;
  padding-top: 120px;
}

.youTubeContent {
  display: flex;
  flex-direction: column;
  color: var(--white);

  h2 {
    font-size: 29px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
    margin-bottom: 20px;

    @include media_xl {
      font-size: 52px;
      margin-bottom: 25px;
    }
  }

  p {
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
    margin-bottom: 20px;

    @include media_xl {
      margin-bottom: 25px;
    }
  }
}

.youTubeLink {
  margin: 0 auto;
}
