@import '../../assets/styles/medias';

.sidePanel {
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: zoom-out;
  transition: all 0.3s;
  top: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  background-color: transparent;
  overscroll-behavior: none !important;

  @include media_xl {
    display: none;
  }
}

.sidePanelVisible {
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.5);
}

.sidePanelContent {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(100%);
  background: var(--white);
  padding: 50px 20px 30px;
  transition: all 0.5s;
  overflow-y: auto;
  width: 100%;
  overscroll-behavior: none !important;
  scrollbar-width: thin;
  scrollbar-color: #888 #e5e0e0;

  @include media_md {
    max-width: 50%;
  }

  @include media_lg {
    width: 40%;
    max-width: 430px;
  }
}

.sidePanelContentVisible {
  transform: translateX(0);
}

.sidePanelCloseButton {
  position: absolute;
  top: 12px;
  right: 16px;
}

.sidePanelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  max-width: 430px;
  margin: 0 auto;
}
