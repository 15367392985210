@import '../../../../assets/styles/medias';

.replyFormContainer {
  width: 90%;
  max-width: 933px;
  margin-top: 17px;
  align-self: flex-end;

  @include media_xl {
    width: 100%;
  }
}

.replyFormLabel {
  display: flex;
  flex-direction: column;
  gap: 13px;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-2);
  margin-bottom: 24px;
}

.replyFormTxt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-left: 6px;
}

.replyFormTextArea {
  border: 1px solid var(--color-border);
  min-height: 92px;
  padding: 10px;
}

.replyFormAuthorImage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-1);
  border-radius: 50%;
  color: var(--white);
  flex-shrink: 0;
}

.replyFormButtonsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 12px;

  @include media_custom_min_width(420px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  @include media_lg {
    gap: 24px;
  }
}

.replyFormCancelBtn,
.replyFormSubmitBtn {
  padding: 9px;
  min-width: 136px;

  @include media_ss {
    padding: 9px 13px;
  }

  @include media_lg {
    padding: 10px 24px;
  }
}
