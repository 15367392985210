@import '../../../../assets/styles/medias';

.collectionModal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  padding: 60px 30px;
  background-color: var(--white);

  @include media_md {
    padding: 60px;
  }
}

.collectionModalTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.collectionModalTitle {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  color: var(--color-2);
  text-align: center;
}
