@import '../../assets/styles/medias';

.LinkButton {
  position: relative;
  z-index: 1;
  background: var(--gradient-1);
  box-shadow: 0px 5px 10px 0px rgba(#23cc88, 0.5);
  font-size: 16px;
  font-weight: 700;
  color: var(--white);
  text-transform: uppercase;
  padding: 20px 35px;
  overflow: hidden;
  text-align: center;
  display: inline-block;
  line-height: 18px;

  &::after {
    display: inline-block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--gradient-2);
    opacity: 0;
    transition: all 0.3s ease-in;
    z-index: -1;
  }

  &:hover {
    color: var(--white);

    &::after {
      opacity: 1;
    }
  }

  @include media_xxl {
    font-size: 20px;
    line-height: 22px;
  }
}

.Orange {
  background: var(--gradient-3);
  box-shadow: 0px 5px 10px 0px rgba(#ff723a, 0.5);

  &::after {
    background: var(--gradient-4);
  }
}

.Blue {
  background: var(--gradient-5);
  box-shadow: 0px 5px 10px 0px rgba(#01cbad, 0.5);

  &::after {
    background: var(--gradient-6);
  }
}

.Purple {
  background: var(--purple);
  box-shadow: 0px 5px 10px 0px rgba(#a238b4, 0.5);

  &::after {
    background: var(--gradient-7);
  }
}
