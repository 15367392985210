@import '../../assets/styles/medias';
$desktop-width: 1400px;

.productWrapperPicture {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  max-width: 350px;

  @include media_custom_min_width($desktop-width) {
    max-width: 576px;
    gap: 25px;
    margin-bottom: 40px;
  }
}

.productPicture {
  width: 100%;
  height: 300px;
  border: 1px solid var(--color-border);
  padding: 29px 24px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  @include media_custom_min_width($desktop-width) {
    padding: 45px 36px;
    height: 475px;
  }
}

.wrapperProductDetailImg {
  padding: 7px 17px;
  width: 167px;
  height: 100%;
  border-right: 1px solid var(--color-border);
  flex-shrink: 0;

  @include media_custom_min_width($desktop-width) {
    padding: 13px 30px;
    width: 288px;
  }
}

.productDetailImg {
  cursor: pointer;
  width: 100%;
  height: 100%;
  max-width: unset !important;
  object-fit: contain;
}

.productDetailImgList {
  display: flex;
  width: 100%;
  height: 130px;
  overflow-x: scroll;
  overflow-y: hidden;
  border: 1px solid var(--color-border);

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    padding: 10px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border: 1px solid #a1a1a1;
    border-radius: 15px;
    transform: rotate(90deg);
    margin: 10px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    margin: 10px;
  }

  @include media_custom_min_width($desktop-width) {
    height: 230px;
  }
}
