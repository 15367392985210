@import '../../../../../assets/styles/medias';

.moveToCollectionsIcon {
  width: 29px;
  height: 29px;

  path {
    fill: var(--color-2);
  }
}

.saveToCollectionsIcon {
  width: 22px;
  height: 27px;
  vertical-align: middle;

  path {
    stroke: var(--color-2);
  }
}

.moveToCollectionsButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-top: 80px;

  @include media_sm {
    flex-direction: row;
    justify-content: center;
    gap: 65px;
  }
}

.moveToCollectionsButton {
  padding: 10px;
  min-width: 216px;

  @include media_sm {
    padding: 20px 10px;
  }
}
