@import '../../../../../assets/styles/medias';
@import '../../../../../assets/styles/placeholders';

.webinarCard {
  position: relative;
  @extend %flex-column;
  justify-content: center;
  width: 350px;

  @include media_custom_min_width(1300px) {
    width: 412px;
  }
}

.webinarCardAspectRatioContainer {
  height: auto;
}

.webinarCardVideo {
  height: 100%;
}

.webinarCardContent {
  @extend %flex-column;
  justify-content: space-between;
  border: 1px solid var(--color-border);
  flex-grow: 1;
}

.webinarCardInfo {
  @extend %flex-column;
  gap: 25px;
  padding: 30px 30px 10px;
}

.webinarCardTitle {
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  color: var(--color-2);
  -webkit-line-clamp: 1;
  -moz-line-clamp: 1;
  -ms-line-clamp: 1;

  &:hover {
    color: var(--color-1);
  }
}

.webinarCardDescription {
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  -ms-line-clamp: 2;
  line-height: 1.6;
}

.webinarCardTitle,
.webinarCardDescription {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
}

.webinarCardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  padding: 20px 30px;
}

.webinarCardViews {
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
}

.webinarCardViewIcon {
  margin-right: 6px;
  width: 28px;
  height: 28px;
}

.webinarCardAuthorName {
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
  text-align: right;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.hidden {
  display: none;
}
