@import '../../../../assets/styles/medias';

.deleteAccountModal {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 640px;
  padding: 40px 30px;
  background-color: var(--white);
  color: var(--color-2);
  overflow: hidden;

  h3 {
    margin-bottom: 25px;
    text-align: center;
  }

  @include media_md {
    padding: 60px;
  }
}

.deleteAccountDescription {
  font-size: 16px;
  margin-bottom: 25px;
  text-align: center;
}

.confirmBtn {
  width: fit-content;
  padding: 16px;
  margin: 0 auto;
}
