@import '../../../../assets/styles/medias';

.list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @include media_custom_min_width(1400px) {
    gap: 20px;
  }
}
