.addModalContent {
  width: 100%;
  max-width: 600px;
}

.addModalContainer {
  width: 100%;
  background-color: var(--white);
  padding: 20px 20px;
}

.addChildModalTitle {
  text-align: center;
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  color: var(--color-2);
}
