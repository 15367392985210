.searchWidget {
  position: relative;
  width: 100%;
}

.searchWidgetIcon {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;

  path {
    fill: #b0b0b0;
  }
}

.searchWidgetInput {
  font-size: 18px;
  line-height: 21px;
  padding: 12px 20px;
  border: 1px solid var(--color-border);
  padding-left: 45px;
  width: 100%;
  transition: none;

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: var(--color-1);
    color: var(--color-1);
  }
}

.searchWidgetInput:focus,
.searchWidgetInput:focus-visible + .searchWidgetIcon {
  path {
    fill: var(--color-1);
  }
}

.searchWidgetInput::placeholder {
  transition: none;
  color: var(--color-2);
}
