@import '../../assets/styles/placeholders';

.message {
  @extend %flex-column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 0 auto;
  text-align: center;
}

.messageImage {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.messageTextInfo {
  @extend %flex-column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  max-width: 400px;
}

.messageTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  color: var(--color-2);
}

.messageDescription {
  font-size: 16px;
  line-height: 1.2;
  color: var(--black);
}
