@import '../../assets/styles/medias';

.coursesProductsLayout {
  background-color: var(--white);
  padding: 100px 0 100px;
}

.coursesProductsContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;

  @include media_xl {
    flex-direction: row;
  }
}
