@import '../../../../assets/styles/medias';

.homeBanner {
  background-image: url(../../../../assets/img/preload-home-banner-mobile.webp);
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 185px;
  padding-bottom: 190px;

  @include media_lg {
    background-position: 45%;
    background-image: url(../../../../assets/img/preload-home-banner-laptop.webp);
    padding-top: 175px;
    padding-bottom: 180px;
  }

  @include media_xl {
    background-position: 65%;
  }

  @include media_custom_min_width(1550px) {
    background-image: url(../../../../assets/img/preload-home-banner.webp);
  }

  @include media_custom_min_width(1900px) {
    background-position: center 5%;
  }

  @include media_custom_min_width(2200px) {
    background-size: 1920px;
  }
}

.homeBannerContent {
  max-width: 700px;

  @include media_custom_min_width(1500px) {
    max-width: 850px;
  }

  @include media_custom_min_width(1800px) {
    max-width: 950px;
  }
}

.homeBannerSubtitle {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: var(--white);
  margin-bottom: 10px;
  margin-top: -9px;

  @include media_ss {
    font-size: 22px;
  }

  @include media_md {
    font-size: 24px;
  }

  @include media_lg {
    font-size: 29px;
  }

  span {
    white-space: nowrap;
  }
}

.homeBannerTitle {
  font-size: 45px;
  line-height: 1.2;
  text-transform: capitalize;
  color: var(--white);
  font-weight: 600;
  margin-bottom: 20px;

  @include media_ss {
    font-size: 50px;
  }

  @include media_sm {
    font-size: 55px;
  }

  @include media_md {
    font-size: 60px;
  }

  @include media_lg {
    font-size: 70px;
  }

  @include media_custom_min_width(1500px) {
    font-size: 80px;
  }
}

.homeBannerDescription {
  font-size: 18px;
  line-height: 1.5;
  color: var(--white);
  margin-bottom: 9px;

  @include media_md {
    font-size: 20px;
  }

  @include media_custom_min_width(1800px) {
    max-width: 850px;
  }
}

.homeBannerBtnBox {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-top: 35px;

  @include media_sm {
    flex-direction: row;
  }

  @include media_md {
    gap: 20px;
  }
}

.homeBannerBtn {
  padding: 20px 35px;
  max-width: 296px;
}
