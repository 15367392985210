.popularWrapper {
  width: 100%;
  max-width: 400px;
  border: 1px solid var(--color-border);
}

.popularWrapperTitleContainer {
  display: flex;
  justify-content: flex-start;
  padding: 21px 30px;
  border-bottom: 1px solid var(--color-border);
}

.popularWrapperTitle {
  margin: 0;
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: var(--color-2);
}

.popularWrapperContent {
  padding: 30px;
}

.popularWrapperList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}
