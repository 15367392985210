.mainFooterSocials {
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.socialIcon {
  width: 20px;
  height: 20px;
}
