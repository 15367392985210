@import '../../../../../assets/styles/medias';

.testimonialsCarouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  :global(.slick-slider) {
    width: 350px;

    @include media_lg {
      width: 890px;
    }

    @include media_xl {
      width: 100%;
    }
  }

  :global(.slick-dots) {
    display: flex;
    flex-direction: column;
    gap: 10px;
    bottom: 50px;
    right: 0;
    width: 20px;

    & li {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    @include media_lg {
      bottom: 50%;
    }
  }
}

.customDot {
  width: 15px;
  height: 15px;
  background-color: var(--color-1);
  opacity: 0.25;
  border-radius: 50%;
}

.customDotActive {
  opacity: 1;
}
