.gearButton {
  position: fixed;
  left: 0;
  top: 252px;
  z-index: 3;
  background: #23cc88;
  color: #fff;
  border: 0;
  padding: 0;
  width: 53px;
  height: 47px;
  font-size: 23px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.gearButtonIcon {
  animation-name: rotate-animation;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  animation-delay: 0;
}
