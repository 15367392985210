@import '../../assets/styles/placeholders';

.paginationContainer {
  @extend %flex-center;
  list-style-type: none;
  gap: 10px;
  margin-top: 60px;
}

.previousClassName,
.nextClassName,
.pageClassName {
  @extend %flex-center;
  width: 40px;
  height: 40px;
  background: transparent;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  padding: 0;
  border-radius: 4px;
  border: 2px solid #dee2e6;
  color: var(--color-2);

  &:hover {
    background-color: var(--color-1);
    border-color: var(--color-1);
    color: var(--white);
  }
}

.activeClassName {
  background-color: var(--color-1);
  border-color: var(--color-1);
  color: var(--white);
}

.previousLinkClassName,
.nextLinkClassName,
.pageLinkClassName {
  @extend %flex-center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  cursor: pointer;
}

.disabledClassName {
  background-color: var(--color-1);
  border-color: var(--color-1);
  opacity: 0.3;
  color: var(--white);
  pointer-events: none;
}
