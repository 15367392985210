@import '../../assets/styles/medias';
$base-width: 300px;

.amazonBooksCarouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  @include media_xl {
    display: none;
  }

  :global(.slick-slider) {
    width: $base-width;

    @include media_md {
      width: ($base-width * 2) + 20px;
    }

    @include media_lg {
      width: ($base-width * 3) + (20px * 2);
    }
  }

  :global(.slick-track) {
    display: flex !important;
    justify-content: center;
  }
}
