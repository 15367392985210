@import '../../../../assets/styles/medias';

.welcome {
  padding-top: 120px;
  padding-bottom: 50px;
}

.welcomeContent {
  margin-bottom: 70px;
}

.welcomeHeading {
  margin-bottom: 70px;
}

.welcomeTitle {
  display: flex;
  flex-direction: column;
  font-size: 29px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-2);
  margin-bottom: 25px;
  margin-top: -8px;

  @include media_ss {
    font-size: 36px;
  }

  @include media_sm {
    font-size: 38px;
  }

  @include media_md {
    font-size: 40px;
  }

  @include media_lg {
    font-size: 52px;
  }
}

.welcomeDescription {
  font-size: 16px;
  line-height: 26px;
}

.welcomeFeatureList {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include media_md {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.welcomeFeatureItem {
  flex: 0 0 auto;
  width: 100%;
}

.welcomeFeatureBox {
  display: flex;
  gap: 20px;

  img {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: transform 0.3s cubic-bezier(0.21, 0.6, 0.44, 2.18);
  }

  &:hover {
    img {
      transform: translate3d(0, -5px, 0);
    }
  }

  @include media_ss {
    gap: 30px;
  }
}

.welcomeFeaturePartIcon {
  flex-shrink: 0;
  width: 70px;
  height: 70px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @include media_xl {
    width: 100px;
    height: 100px;
  }
}

.welcomeFeatureTxt {
  flex-grow: 1;
}

.welcomeFeatureTitle {
  font-size: 22px;
  line-height: 34px;
  font-weight: 700;
  color: var(--color-2);
  margin-bottom: -8px;
  margin-top: -7px;

  @include media_lg {
    font-size: 24px;
  }
}

.welcomeDivider {
  width: 80px;
  height: 4px;
  background: linear-gradient(145deg, #8ecf35, #23cc88);
  margin-bottom: 20px;
  margin-top: 10px;
  border-radius: 2px;
}

.welcomeFeatureDescription {
  font-size: 16px;
  line-height: 26px;
  margin-top: -6px;
  margin-bottom: -8px;
}

.welcomeImageContainer {
  position: relative;
  margin-bottom: 70px;
  max-width: 400px;

  img {
    width: 100%;
    object-fit: cover;
    max-height: 470px;
  }

  @include media_sm {
    max-width: 376px;
  }

  @include media_lg {
    margin-left: 30px;
  }
}

.welcomeVideoBtn {
  width: 100px;
  height: 100px;
  line-height: 100px;
  font-size: 48px;
  display: inline-block;
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  text-align: center;
  background: linear-gradient(145deg, #8ecf35, #23cc88);

  // &:hover i {
  //   color: #ff5a4c;
  // }

  i {
    color: var(--white);
    transition: all 0.3s;
  }
}

.welcomeVideoBtn {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
}
