@import '../../../../../assets/styles/medias';

.facilityCard {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 340px;
  height: 360px;

  &:hover {
    .facilityIcon {
      img {
        animation-name: wobble-vertical;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
      }
    }
  }

  @include media_xl {
    width: 355px;
  }

  @include media_custom_min_width(1400px) {
    width: 400px;
  }
}

.facilityCardTwo {
  .facilityIcon {
    background: linear-gradient(-145deg, #ff4f58, #ffb400);
    box-shadow: 0px 25px 20px 0px rgba(#ff723a, 0.3);
  }
}

.facilityCardThree {
  .facilityIcon {
    background: linear-gradient(-145deg, #e85aca, #fc4e65);
    box-shadow: 0px 25px 20px 0px rgba(#e85aca, 0.3);
  }
}

.facilityCardContent {
  padding: 0 30px 35px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media_custom_min_width(1400px) {
    padding: 0 50px 50px;
  }
}

.facilityIcon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(145deg, #8ecf35, #23cc88);
  box-shadow: 0px 25px 20px 0px rgba(#23cc88, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;

  img {
    width: 30px;
  }

  @include media_lg {
    width: 70px;
    height: 70px;

    img {
      width: 35px;
    }
  }
}

.facilityTitle {
  font-size: 24px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 20px;
  color: var(--color-2);

  @include media_ss {
    font-size: 24px;
  }
}

.facilityTxt {
  margin-bottom: -8px;
  margin-top: -4px;
  color: var(--black);
}

@keyframes wobble-vertical {
  16.65% {
    transform: translateY(8px);
  }
  33.3% {
    transform: translateY(-6px);
  }
  49.95% {
    transform: translateY(4px);
  }
  66.6% {
    transform: translateY(-2px);
  }
  83.25% {
    transform: translateY(1px);
  }
  100% {
    transform: translateY(0);
  }
}
