@import '../../../assets/styles/medias';
@import '../../../assets/styles/placeholders';

.container {
  position: absolute;
  @extend %flex-column;
  justify-content: center;
  align-items: flex-start;
  top: 245%;
  left: 0;
  right: 0;
  background: white;
  padding: 17px 22px;
  z-index: 3;

  @include media_sm {
    top: 270%;
  }

  @include media_lg {
    width: 310px;
    padding: 20px 11px;
    top: 110%;
    border-top: 6px solid #42cd6f;
    left: unset;
    right: 0;
  }
}

.profileHeader {
  display: none;

  @include media_lg {
    display: flex;
  }
}

.profileInfo {
  @extend %flex-column;
  padding-top: 20px;
  padding-left: 15px;
}

.profileName {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-2);
  cursor: default;
}

.profileEmail {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #636363;
  cursor: default;
}

.profileName,
.profileEmail {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileList {
  @extend %flex-column;
  max-width: 220px;
  width: 100%;

  @include media_lg {
    margin-top: 23px;
    padding-left: 7px;
  }
}

.profileItem {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-2);
  margin-bottom: 13px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 11px;
  text-transform: uppercase;

  svg {
    path {
      fill: var(--color-2) !important;
    }
  }

  &:last-child {
    margin-bottom: unset;
    padding-bottom: 0;
  }

  &:hover,
  &:focus,
  &:focus-visible {
    color: #23cc88;
    svg {
      path {
        fill: #23cc88 !important;
      }
    }
  }
}

.profileImg {
  @extend %flex-center;
  background: #d9d9d9;
  border-radius: 50%;
  width: 102px;
  height: 102px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.profileItemIcon {
  width: 16px;
  flex-shrink: 0;
  margin-right: 10px;
}

.border {
  border-bottom: 1px solid var(--color-2);
}
