@import '../../assets/styles/medias';

.footer {
  background-color: #8c6adc;
  background-repeat: no-repeat;
  background-size: cover;

  @include media_lg {
    background-color: none;
    background-position: 22%;
    background-image: url(../../assets/img/footer-background.webp);
  }

  @include media_custom_min_width(1700px) {
    height: 750px;
  }
}
