@import '../../../assets/styles/medias';
@import '../../../assets/styles/placeholders';

.header {
  @extend %flex-column;
  justify-content: center;
}

.headerTopContainer {
  background: linear-gradient(11deg, #8ecf35, #23cc88);
  padding: 50px 0 30px;

  @include media_custom_min_width(665px) {
    padding: 20px 0 20px;
  }

  @include media_lg {
    padding: 0;
  }
}

.headerTop {
  position: relative;
  @extend %flex-column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  @include media_lg {
    flex-direction: row;
    justify-content: space-between;
  }
}

.headerTopTxt {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: var(--white);
  text-align: center;
  max-width: 200px;

  @include media_sm {
    max-width: none;
  }
}

.headerTopNav {
  @extend %flex-center;
  flex-wrap: wrap;
}

.headerTopLink {
  position: relative;
  @extend %flex-center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--white);
  border-right-width: 0;

  &:last-child {
    border-right-width: 1px;
  }

  &:hover,
  &:focus-visible {
    background: var(--white);

    .headerTopIcon {
      path {
        fill: var(--color-1);
      }
    }
  }

  @include media_lg {
    width: 50px;
    height: 50px;
    border-top: none;
    border-bottom: none;
  }
}

.headerTopTelegramIcon {
  width: 17px;
  height: 16px;
}

.headerTopFacebookIcon {
  width: 13px;
  height: 16px;
}

.headerTopInstagramIcon {
  width: 16px;
  height: 16px;
}

.headerTopShoppingCartIcon,
.headerTopUserIcon {
  path {
    fill: var(--white);
  }
}

.headerTopCartItems {
  position: absolute;
  @extend %flex-center;
  width: 16px;
  height: 16px;
  bottom: 4px;
  right: 4px;
  font-size: 12px;
  line-height: normal;
  color: var(--white);
  border-radius: 50%;
  background: #ff4f58;
  overflow: hidden;

  @include media_lg {
    bottom: 8px;
    right: 8px;
  }
}
