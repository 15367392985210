@import '../../../assets/styles/medias';

.productTabs {
  padding: 16px 13px 40px;
  border: 1px solid var(--color-border);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include media_lg {
    padding: 75px 70px;
  }

  @include media_xl {
    padding: 75px 90px;
  }
}

.wrapperTabs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  justify-content: center;
  align-items: center;
  gap: 25px;
  width: 100%;

  @include media_xl {
    padding: 0 65px;
  }
}

.tab {
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  text-align: right;
  color: var(--color-2);
  padding: 16px 12px;
  min-width: 140px;
  max-width: 235px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #d7d7d7;
}

.activeTab {
  color: white;
  background: linear-gradient(94.41deg, #72cf4d 0%, #30cc7f 99.38%);
}

.tabContent {
  padding-top: 40px;
  width: 100%;
  margin: 0 auto;
  font-size: 18px !important;
}

.videoTitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 30px;
  margin-bottom: 10px;
  color: var(--color-2);

  @include media_xl {
    font-size: 30px;
    line-height: 1.2;
    margin-top: 35px;
    margin-bottom: 30px;
  }
}

.videoDescription {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--describtion-color);

  @include media_xl {
    font-size: 18px;
    line-height: 21px;
  }

  a {
    color: var(--link-color);
    text-decoration: underline;
    transition: none;

    &:hover {
      color: var(--color-1);
      text-decoration: underline;
    }
  }
}

.videoTabWrapper {
  max-width: 820px;
  margin: 0 auto;
}

.wrapperContent {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media_xl {
    padding: 0 65px;
  }
}

.wrapperContentItem {
  display: flex;
  flex-direction: column;
  gap: 5px;

  @include media_md {
    flex-direction: row;
    gap: 20px;
  }
}

.wrapperText {
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);

  @include media_md {
    width: 50%;
  }
}

.wrapperCheckedText {
  display: flex;
  gap: 8px;
  text-align: left;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: var(--black);

  @include media_md {
    width: 50%;
  }
}
