.mainFooterListItem {
  margin-bottom: 10px;
}

.mainFooterLink {
  margin-bottom: 10px;
  transition: all 0.3s;
  color: var(--white);
  font-size: 16px;
  line-height: 26px;

  &:hover {
    padding-left: 5px;
    color: var(--color-1);
  }
}
