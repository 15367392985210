@import '../../../assets/styles/placeholders';

.card {
  @extend %flex-column;
  align-items: center;
  gap: 12px;
  word-break: break-word;
  cursor: pointer;
  height: 100%;

  h3 {
    font-size: 24px;
    line-height: 100%;
    max-width: 100%;
  }

  img {
    width: 100%;
    max-height: 375px;
    object-fit: cover;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  span {
    font-size: 20px;
    color: var(--color-1);
    font-weight: bold;
    line-height: 100%;
  }
}
