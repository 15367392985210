.collectionControlPanel {
  position: absolute;
  width: max-content;
  max-width: 100%;
  border-top: 7px solid var(--color-teal-green);
  background-color: var(--white);
}

.collectionControlPanelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 13px 8px 13px 15px;
  border: 1px solid var(--color-border);
  border-top: 0;
}

.collectionControlPanelItem {
  display: flex;
  align-items: center;
  gap: 9px;
  font-size: 16px;
  line-height: 19px;
  color: var(--black);
}
