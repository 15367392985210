@import '../../assets/styles/placeholders';

.category {
  box-sizing: border-box;
  @extend %flex-column;
  justify-content: center;
  border: 1px solid var(--color-border);
  width: 100%;
}

.categoryHeaderContainer {
  @extend %flex-align-center;
  gap: 10px;
  padding: 21px 30px;
  cursor: pointer;
}

.categoryTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--color-2);
  margin: 0;
}

.categoryExpandArrowIcon {
  width: 24px;
  height: 24px;
}

.treeViewListArrowIconExpanded,
.categoryExpandArrowIconRotated {
  transform: rotate(180deg);
}

.categoryTreeViewContainer {
  border-top: 1px solid var(--color-border);
  padding: 30px 30px 20px;
}

.treeViewList {
  @extend %flex-column;
  justify-content: center;
}

.treeViewListItem {
  @extend %flex-align-center;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--color-black);
  margin-bottom: 10px;
}

.treeViewCheckbox {
  position: relative;
  width: 15px;
  height: 15px;
  background-color: #f3f3f4;
  border-radius: 2px;
  border: 1px soild var(--light-gray);
  margin-right: 15px;
  transition: none;
  cursor: pointer;
}

.treeViewCheckboxSelected {
  background-color: var(--color-1);

  &::before {
    content: '\eed6';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: 'IcoFont';
    color: var(--white);
    font-weight: 900;
    font-size: 10px;
    line-height: 1;
  }
}

.treeViewItemName {
  margin-right: 5px;
}

.treeViewListArrowIcon {
  width: 18px;
  height: 18px;
}
