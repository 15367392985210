@import '../../../../assets/styles/medias';

.homeSection {
  padding: 120px 0 120px;
}

.homeSectionHeading {
  max-width: 768px;
  margin: 0 auto 45px;
}

.homeSectionTitle {
  font-size: 29px;
  font-weight: 600;
  line-height: 1.2;
  color: var(--color-2);
  text-align: center;
  margin-bottom: 20px;

  @include media_xl {
    font-size: 52px;
    margin-bottom: 25px;
  }
}

.homeSectionDescription {
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: var(--black);
}

.homeSectionBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
}
