@import '../../assets/styles/placeholders';

.slider {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 27px;
}

.sliderDisabled {
  opacity: 0.6;
}

.thumbClassName {
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4acc86;
  border: 5px solid var(--white);
}

.trackClassName:nth-child(2) {
  background-color: #4acc86;
  height: 10px;
  border-radius: 27px;
}

.rangeContainer {
  box-sizing: border-box;
  @extend %flex-column;
  justify-content: center;
  border: 1px solid var(--color-border);
  width: 100%;
}

.rangeHeader {
  padding: 21px 30px;
  border-bottom: 1px solid var(--color-border);
}

.rangePriceTitle {
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  color: var(--color-2);
  margin: 0;
}

.rangeContent {
  padding: 20px 20px 30px 32px;
}

.rangeForm {
  @extend %flex-column;
  justify-content: center;
  overflow: hidden;
}

.rangeFilters {
  @extend %flex-column;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 14px;
}

.rangeInputLabel {
  position: relative;
  @extend %flex-align-center;
  justify-content: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--black);

  span {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-color: #f3f3f4;
    cursor: pointer;
  }
}

.rangeCheckbox {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  opacity: 0;
  width: 15px;
  height: 15px;
}

.rangeCheckbox:checked + span {
  background-color: var(--color-1);

  &::before {
    position: absolute;
    content: '\eed6';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-shrink: 0;
    font-family: 'IcoFont';
    color: var(--white);
    font-weight: 900;
    font-size: 10px;
    line-height: 1;
  }
}

.rangeInputsContainer {
  @extend %flex-align-center;
  justify-content: space-between;
  margin-bottom: 18px;
}

.rangeInput {
  text-align: center;
  width: 95px;
  height: 37px;
  border: 1px solid var(--color-border);
}

.rangeFormBtn {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #4acc86;
  background: transparent;
  border: 0;
  padding: 0;
  margin-left: auto;
  margin-top: 15px;

  &:hover {
    color: var(--color-2);
  }
}

.rangeInputDisabled {
  opacity: 0.6;
}
