.modalContentClassName {
  overflow: visible;

  button {
    top: -25px;
    right: -25px;
  }
}

.modalCertificateContainer {
  max-height: 100%;
  max-width: 1200px;

  img {
    max-height: 90vh;
    max-width: 100%;
    object-fit: contain;
  }
}
