@import '../../assets/styles/medias';

.gearButtonHidden {
  @include media_xl {
    display: none;
  }
}

.productsCategoriesContainer {
  display: none;

  @include media_xl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    width: 100%;
  }
}

.productsSearchWidgetContainer {
  display: none;

  @include media_xl {
    display: block;
    width: 100%;
  }
}

.productsCardsContainer {
  flex-grow: 1;
  max-width: 826px;
  margin: 0 auto;
  width: 100%;

  @include media_xl {
    margin: 0;
  }
}

.productsCards {
  display: grid;
  grid-template-columns: repeat(auto-fit, 255px);
  justify-content: center;
  justify-items: center;
  gap: 35px 30px;
  margin-top: 25px;
}

.productsTags {
  margin-bottom: 0;
}

.priceRangeContainer,
.popularTagsContainer {
  display: none;

  @include media_xl {
    display: block;
    width: 100%;
  }
}
