@import '../../../../../assets/styles/medias';

.deleteCollectionIcon {
  width: 25px;
  height: 28px;
}

.deleteCollectionTxt {
  font-size: 18px;
  line-height: 21px;
  color: var(--black);
  margin-bottom: 20px;
  text-align: center;
}

.deleteCollectionButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @include media_sm {
    flex-direction: row;
    justify-content: center;
    gap: 65px;
  }
}

.deleteCollectionButton {
  padding: 10px;
  min-width: 216px;

  @include media_sm {
    padding: 20px;
  }
}
