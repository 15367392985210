.collectionsList {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 152px;
  min-height: 152px;
  overflow-y: auto;
}

.emptyTitle {
  text-align: center;
}

.collectionsEmptyContent {
  margin-bottom: 25px;

  img {
    width: 200px;
    height: 150px;
  }
}
