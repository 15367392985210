.formSelectLabel {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-2);
}

.formSelect {
  width: 100%;
  font-size: 16px;
  line-height: 19px;
  padding: 15px;
  border: 1px solid var(--color-border);
  color: var(--color-2);

  &:focus,
  &:focus-visible {
    outline: none;
    border-color: var(--color-1);
    color: var(--color-1);
  }
}

.formSelectErrorText {
  font-size: 15px;
  color: var(--color-error);
}
